import React from "react"
import Layout from "../components/layout"
import img1 from "../../static/images/kazim-yardimci/kazim-yardimci-1.jpg"
import img2 from "../../static/images/kazim-yardimci/kazim-yardimci-2.jpg"
import img3 from "../../static/images/kazim-yardimci/kazim-yardimci-3.jpg"
import img4 from "../../static/images/kazim-yardimci/kazim-yardimci-4.jpg"
import {GatsbySeo} from 'gatsby-plugin-next-seo/src/meta/gatsby-seo';
import useSiteMetadata from '../hooks/use-site-metadata';

const KazimYardimci = ({location}) => {
    const { siteUrl } = useSiteMetadata();

    return (
        <Layout>
            <GatsbySeo
                title="Kazım Yardımcı"
                description="Sayın Kâzım YARDIMCI, 1936 Adıyaman doğumludur. İlk ve Orta öğrenimini Adıyaman’da, Lise öğrenimini Malatya’da görmüştür. İmam Hatip Liselerinde yada ilahiyat fakültelerinde okumadığı halde Arap Dili ve Osmanlıca’yı bizzat kendi özel çabalarıyla öğrenmiştir"
                openGraph={{
                    url: `${siteUrl}${location.pathname}`,
                    title: "Kazım Yardımcı",
                    type: "profile",
                    profile: {
                        firstName: 'Kazım',
                        lastName: 'YARDIMCI',
                        gender: 'male',
                    },
                    images: [
                        {
                            url: `${siteUrl}/images/kazim-yardimci/kazim-yardimci-1.jpg`
                        },
                        {
                            url: `${siteUrl}/images/kazim-yardimci/kazim-yardimci-2.jpg`
                        },
                        {
                            url: `${siteUrl}/images/kazim-yardimci/kazim-yardimci-3.jpg`
                        },
                        {
                            url: `${siteUrl}/images/kazim-yardimci/kazim-yardimci-4.jpg`
                        }
                    ]
                }}
            />
            <section id="page-title" className="page-title-center">

                <div className="container">
                    <h1>Kazım Yardımcı</h1>
                    <span>Kazım Yardımcı hakkında bilgiler</span>
                    {/*<ol className="breadcrumb">*/}
                    {/*    <li className="breadcrumb-item"><a href="#">Home</a></li>*/}
                    {/*    <li className="breadcrumb-item active" aria-current="page">Blog</li>*/}
                    {/*</ol>*/}
                </div>

            </section>
            <section id="content">
                <div className="content-wrap pt-5">
                    <div className="container clearfix">
                        <div className="fancy-title title-bottom-border">
                            <h4>Kâzım YARDIMCI</h4>
                        </div>

                        <p>Sayın Kâzım YARDIMCI, 1936 Adıyaman doğumludur. İlk ve Orta öğrenimini Adıyaman’da, Lise öğrenimini Malatya’da görmüştür. İmam Hatip Liselerinde yada ilahiyat fakültelerinde okumadığı halde Arap Dili ve Osmanlıca’yı bizzat kendi özel çabalarıyla öğrenmiştir. Soy kökü anne tarafından “<b>Haşimi</b>”
                            ailesine dayanmaktadır. Kendisi halen, emekli memur olarak, Adıyaman’da ailesiyle birlikte ikâmet etmektedir. Hemen hemen tüm hayatını Tasavvufi araştırmalar üzerine adamıştır., çalışmalarını yıllardır bu sahada sürdürmektedir.Kâzım YARDIMCI, şu ana kadar sekiz (8) kitap yazmış,
                            bunların hepsi İngilizce’ye çevrilmiştir.</p>
                        <p>Çağımızın tasavvufçu mütefekkiri Sayın Kâzım YARDIMCI’nın mükemmel fikirlerinden bazıları:</p>
                        <ul>
                            <li>“Sonsuzluk… İşte bu Ulûhiyettir”.</li>
                            <li>“Edison ve Einstein Tanrı’nın Varlığına ve Tekliğine inanmış, kalben Kur’an’a ve Hz. Muhammed’e hayran olup Müslüman olmuşlar, ancak bunu açıklamamışlardı”.</li>
                            <li>“Dünyaya ilk yağmur, Tanrı tarafından ve Melekût âlemindeki Rahmet Deryası adıyla anılan ırmaktan yağmıştır”.</li>
                            <li>“Dik yürüme özelliği yalnızca insana verilmiştir”.</li>
                            <li>“Yoktan varlık olamaz”.</li>
                            <li>“Can ayrı, ruh ayrıdır”.</li>
                            <li>“Meyveleri güneş oldurur, tadını ay ve yıldızlar verir”.</li>
                            <li>“Nebevi Hilafetin olmadığı yerde şeriat yoktur, ilm-ihal vardır”.</li>
                            <li>“İnsan atomu parçaladı fakat bir tek atom yapamayacaktır”.</li>
                        </ul>
                        <p>Sn. Kâzım YARDIMCI diyor ki;</p>
                        <p>“Allah’ın Dini ulvidir(çok yüce ve mukaddestir) madde ve para, sufli- alçaktır. Yüce ve mukaddes olan, alçak olan madde ve para ile değiştirilemez. Kur’an, hadis ve dini kitapları, para ile satan bunlarla geçinen, Allah huzurunda çok şiddetli bir ikapla karşılaşır.”</p>
                        <p>Saygılar, Sevgiler..</p>
                        <p className="float-right">Kâzım YARDIMCI</p>

                        <div className="divider"></div>

                    </div>
                    <div className="container clearfix">

                        <div className="masonry-thumbs grid-container grid-4" data-lightbox="gallery">
                            <a className="grid-item" href={img1} data-lightbox="gallery-item"><img src={img1} alt="Kazım Yardımcı"/></a>
                            <a className="grid-item" href={img2} data-lightbox="gallery-item"><img src={img2} alt="Kazım Yardımcı"/></a>
                            <a className="grid-item" href={img3} data-lightbox="gallery-item"><img src={img3} alt="Kazım Yardımcı"/></a>
                            <a className="grid-item" href={img4} data-lightbox="gallery-item"><img src={img4} alt="Kazım Yardımcı"/></a>
                        </div>

                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default KazimYardimci
